import { PURCHASE_ITEM } from '../constant';

const INIT_STATE = {
  fund: null,
  firm: null,
  amount: 0,
};

const Investment = (state = INIT_STATE, action) => {
  switch (action.type) {
    case PURCHASE_ITEM: {
      return { ...state, ...action.payload };
    }
    default:
      return state;
  }
};

export default Investment;
