import { SET_USER_INFORMATION, RESET_INVESTOR } from '../constant';

const INIT_STATE = {
  user: { userType: 2 },
};

const Investor = (state = INIT_STATE, action) => {
  switch (action.type) {
    case SET_USER_INFORMATION: {
      return { ...state, user: action?.payload };
    }

    case RESET_INVESTOR: {
      return {
        user: { userType: 2 },
        bankDetails: null,
      };
    }

    default:
      return state;
  }
};

export default Investor;
