import { NavLink } from 'react-router-dom';
import '../PageNotFound/index.css';

const PageError = ({ heading = `Apologies, something went wrong.` }) => {
  return (
    <>
      <div className="container c-container">
        <div className="pg-wrp">
          <div className="page-not-fnd-container">
            <div className="page-not-fnd-title">
              <h3 className="ph-hed">{heading}</h3>
            </div>
            <br />
            <div className="lnk-cnt">
              <NavLink
                to="/"
                onClick={(event) => {
                  event.preventDefault();
                  window.location.reload();
                }}
                className="red-lnk"
              >
                Refresh page
              </NavLink>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default PageError;
