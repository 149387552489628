import configuration from 'constants/config';
import { UserType } from 'further-types/user';

type User = {
  _id: string;
  userType?: UserType;
};

const getUserType = (user: User) => {
  const userIsAdviser = user?.userType === configuration.userType.advisor;
  const userIsOrg = user?.userType === configuration.userType.organisation;
  const userIsInvestor = user?.userType === configuration?.userType?.investor;

  return {
    userIsInvestor,
    userIsAdviser,
    userIsOrg,
  };
};

export default getUserType;
