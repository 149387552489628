import React, { useEffect } from 'react';
import { Router, matchPath } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import mixpanel from 'mixpanel-browser';

import { history, store } from './redux/store';
import axiosUtil from './lib/axios';
import configure from './constants/config';
import { useCurrentUserInfo } from 'hooks/data/user/useCurrentUserInfo';
import { CommonRoutes, CustomRoutes } from './routes';
import { initLogrocket, initSentry, identifySentry } from 'lib/tracking';

import './App.css';
import './components/index.css';
import 'helpers/pdf/fonts';
import ContentLoader from './components/ContentLoader';
import useUser from 'hooks/ui/useUser';
import WhiteLabellingProvider, {
  useWhiteLabellingContext,
} from 'contexts/WhiteLabellingContext';
import PageError from 'views/PageError';
const PortalLayout = React.lazy(() => import('./layouts/Portal'));
const RawLayout = React.lazy(() => import('./layouts/Raw'));

axiosUtil.initalise(store);

function LoadingFallback() {
  return <ContentLoader />;
}

function AppContent() {
  const { isLoading, isSubdomain, status } = useWhiteLabellingContext();

  const routes = CommonRoutes.filter((rec) =>
    isSubdomain ? !rec.hideForSubDomains : !rec.onlyAllowedInSubDomain,
  );

  const isCustomRoute = CustomRoutes.some(({ path, exact }) =>
    matchPath(location?.pathname, { path, exact }),
  );

  const Layout = isCustomRoute ? RawLayout : PortalLayout;

  if (status === 'error' && isSubdomain) {
    return <PageError />;
  }

  return (
    <React.Suspense fallback={<LoadingFallback />}>
      {isLoading ? <LoadingFallback /> : <Layout routes={routes} />}
    </React.Suspense>
  );
}

function App() {
  const user = useUser();
  useCurrentUserInfo();

  useEffect(() => {
    if (configure.STAGE === 'production') {
      // redirect from https://joinfurther.com to https://www.joinfurther.com
      // because user cookies/localstorage will only exist on one domain so this
      // ensures that users always stay logged in regardless of what URL they
      // load. E.g. They may follow urls from further emails that are non-www.
      // Ideally this would happen on the web-server level (ie. cloudfront atm)
      if (window.location.host.startsWith('joinfurther.com')) {
        window.location.host = 'www.joinfurther.com';
      }
      mixpanel.init('6c462f59e373c8adc607d57788fac511', { debug: true });
    }
  }, []);

  useEffect(() => {
    identifySentry(user);
    initLogrocket(user);
  }, [user]);

  useEffect(() => {
    initSentry();
    initLogrocket();
  }, []);

  return (
    //@ts-expect-error abc
    <ConnectedRouter history={history}>
      <Router history={history}>
        <WhiteLabellingProvider>
          <AppContent />
        </WhiteLabellingProvider>
      </Router>
    </ConnectedRouter>
  );
}

export default App;
