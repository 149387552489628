import { message } from 'antd';
import { AxiosError } from 'axios';
import { ApiResponse } from 'further-types/api';

function isAxiosError(e: any): e is AxiosError<ApiResponse> {
  return !!e.response?.data?.responseMsg;
}

function isTokenError(error: AxiosError<ApiResponse>) {
  return error.response.data.responseMsg
    .toLowerCase()
    .includes('token not passed');
}

export default function showRequestError(
  error: Error,
  defaultErrorMessage?: string,
) {
  if (isAxiosError(error)) {
    if (isTokenError(error)) {
      return;
    }

    message.error(error.response.data.responseMsg);
    return;
  }

  if (defaultErrorMessage) {
    message.error(defaultErrorMessage);
  }
}
