import axios from 'axios';

import {
  ADVISER_INVITE_INVESTOR,
  RESET_ADVISER_INVITE_INVESTOR,
} from '../constant';

export const onboardingFlow = (value) => {
  return () => {
    return axios
      .put(`adviser/onboarding-adviser`, value)
      .then((response) => {
        if (response.status === 200) {
          return Promise.resolve(response);
        }
      })
      .catch((error) => {
        return Promise.resolve({ error: error.response.data });
      });
  };
};

export const getPreviousInvestors = () => {
  return () => {
    return axios
      .get(`adviser/get-previous-investors`)
      .then((response) => {
        if (response.status === 200) {
          return Promise.resolve(response);
        }
      })
      .catch((error) => {
        return Promise.resolve({ error: error.response.data.responseMsg });
      });
  };
};

export const inviteAdviserInvestor = (value) => {
  return (dispatch) => {
    dispatch({
      type: ADVISER_INVITE_INVESTOR,
      payload: value,
    });
  };
};

export const inviteAdviserInvestorFlow = (value) => {
  return () => {
    return axios
      .post(`adviser/invite-investor`, value)
      .then((response) => {
        if (response.status === 200) {
          return Promise.resolve(response);
        }
      })
      .catch((error) => {
        return Promise.resolve({ error: error.response.data.responseMsg });
      });
  };
};

export const resetAdviserInvite = () => {
  return (dispatch) => {
    dispatch({
      type: RESET_ADVISER_INVITE_INVESTOR,
    });
  };
};
