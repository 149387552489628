import axios from 'axios';

import { logout } from '@redux/actions/User';

const axiosUtil = {
  initalise: (store) => {
    axios.defaults.baseURL = process.env.REACT_APP_BASEURL;
    axios.interceptors.request.use(
      (axiosConfig) => {
        axiosConfig.headers['strict-transport-security'] =
          'max-age=63072000; includeSubdomains; preload';
        axiosConfig.headers['content-security-policy'] =
          "default-src 'none'; img-src 'self'; script-src 'self'; style-src 'self'; object-src 'none'";
        axiosConfig.headers['x-content-type-options'] = 'nosniff';
        axiosConfig.headers['x-frame-options'] = 'DENY';
        axiosConfig.headers['x-xss-protection'] = '1; mode=block';
        axiosConfig.headers['Content-Type'] = 'application/json';

        const token = localStorage.getItem('token');
        if (token) {
          axiosConfig.headers['Authorization'] = `Bearer ${token}`;
        }

        return axiosConfig;
      },
      (error) => {
        Promise.reject(error);
      },
    );
    // Response Interceptor - logout the user if the api sends a request to do that
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      function (error) {
        if (error.response.data.is_logout === 1) {
          store.dispatch(logout({ queryClient: undefined }));
        }
        return Promise.reject(error);
      },
    );
  },
};

export default axiosUtil;
