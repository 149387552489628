const addCustomFavicons = (firm?: { investmentFirmLogo?: string }) => {
  const link = document.createElement('link');
  link.id = 'favicon';
  link.rel = 'icon';
  const appleTouch = document.createElement('link');
  appleTouch.id = 'apple-touch-icon';
  appleTouch.rel = 'apple-touch-icon';

  if (firm && firm?.investmentFirmLogo) {
    const v = new Date().toISOString();
    link.href = firm.investmentFirmLogo + `?v=${v}`;
    appleTouch.href = firm.investmentFirmLogo + `?v=${v}`;
  } else {
    link.href = 'assets/images/favicon-icon.ico';
    appleTouch.href = 'assets/images/apple-touch.png';
  }
  document.head.appendChild(link);
  document.head.appendChild(appleTouch);
};

export default addCustomFavicons;
