import { Font } from '@react-pdf/renderer';

import MaisonNeueBook from 'assets/fonts/MaisonNeue-Book.ttf';
import MaisonNeueMedium from 'assets/fonts/MaisonNeue-Medium.ttf';
import MaisonNeueBookItalic from 'assets/fonts/MaisonNeue-BookItalic.ttf';
import MaisonNeueBold from 'assets/fonts/MaisonNeue-Bold.ttf';

Font.register({
  family: 'MaisonNeue',
  src: MaisonNeueBook,
});

Font.register({
  family: 'MaisonNeue-Book',
  src: MaisonNeueBook,
});

Font.register({
  family: 'MaisonNeue-Bold',
  src: MaisonNeueBold,
});

Font.register({
  family: 'MaisonNeue-Medium',
  src: MaisonNeueMedium,
});

Font.register({
  family: 'MaisonNeue-BookItalic',
  src: MaisonNeueBookItalic,
  fontStyle: 'italic',
});

Font.registerHyphenationCallback((word) => [word]);
