import configuration from 'constants/config';
import { applyMiddleware, legacy_createStore as createStore } from 'redux';
import { persistStore, persistReducer } from 'redux-persist';
import thunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import storage from 'redux-persist/lib/storage';
import autoMergeLevel2 from 'redux-persist/lib/stateReconciler/autoMergeLevel2';
import { createBrowserHistory } from 'history';
import reducers from './reducers';

const logger = createLogger({});

export const history = createBrowserHistory();

const persistConfig = {
  key: 'root',
  storage,
  stateReconciler: autoMergeLevel2,
};

const persistedReducer = persistReducer(persistConfig, reducers(history));

const environment = configuration['STAGE'];
const loggerEnvironments = ['local', 'uat'];

export const setupStore = (preloadedState) =>
  createStore(
    persistedReducer,
    preloadedState,
    loggerEnvironments.includes(environment)
      ? applyMiddleware(thunk, logger)
      : applyMiddleware(thunk),
  );

export const store = setupStore({});

export const persistor = persistStore(store);
