import { useQuery } from '@tanstack/react-query';
import { Api } from 'further-types/white-labelling';
import { keepPreviousData } from '@tanstack/react-query';
import axios from 'axios';
import { ApiResponse } from 'further-types/api';

export function useWhiteLabelling(params: Api.WhiteLabellingRequest) {
  return useQuery({
    queryKey: ['white-labelling', params.domain],
    queryFn: async () => {
      const response = await axios.get<ApiResponse<Api.WhiteLabellingResponse>>(
        `investor/white-label-config?domain=${params.domain}`,
      );
      return response.data.data;
    },
    staleTime: 60 * 60 * 1000,
    retry: 1,
    retryDelay: 1000,
    placeholderData: keepPreviousData,
    enabled: !!params.domain,
  });
}
