import { Spin } from 'antd';

import './index.css';

const ContentLoader = () => {
  return (
    <div className="content-loader">
      <Spin />
    </div>
  );
};

export default ContentLoader;
