//Investor
export const RESET_INVESTOR = 'RESET_INVESTOR';
export const SET_USER_INFORMATION = 'SET_USER_INFORMATION';
export const GET_USER_INFORMATION = 'GET_USER_INFORMATION';
export const FORGET_PASSWORD = 'FORGET_PASSWORD';

//Investment
export const PURCHASE_ITEM = 'PURCHASE_ITEM';

//WhiteLabelling
export const GET_WHITE_LABELLING_DETAILS = 'GET_WHITE_LABELLING_DETAILS';

//Adviser
export const ADVISER_INVITE_INVESTOR = 'ADVISER_INVITE_INVESTOR';
export const RESET_ADVISER_INVITE_INVESTOR = 'RESET_ADVISER_INVITE_INVESTOR';
