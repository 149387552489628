import {
  ADVISER_INVITE_INVESTOR,
  RESET_ADVISER_INVITE_INVESTOR,
} from '../constant';

const INIT_STATE = {
  adviserInviteInvestor: {
    investorInformation: {},
    investmentInformation: {},
    adviserBankInformation: {},
    fundInformation: {},
  },
};

const AdviserInviteInvestor = (state = INIT_STATE, action) => {
  switch (action.type) {
    case ADVISER_INVITE_INVESTOR: {
      return { ...state, adviserInviteInvestor: action?.payload };
    }
    case RESET_ADVISER_INVITE_INVESTOR: {
      return {
        ...state,
        adviserInviteInvestor: INIT_STATE?.adviserInviteInvestor,
      };
    }
    default:
      return state;
  }
};

export default AdviserInviteInvestor;
