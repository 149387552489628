export const url = process.env.REACT_APP_BASEURL;
export const stage = process.env.REACT_APP_STAGE;

const phoneHours = 'Mon-Fri 9.30-17.00';
const title = 'Further';
const furtherInvestorHelpEmail = 'invest@joinfurther.com';

const userType = {
  investor: 2,
  advisor: 1,
  organisation: 0,
};

const investorType = {
  everydayInvestor: 1,
  sophisticatedInvestor: 2,
  highNetWorthInvestor: 3,
  professionalClient: 4,
  advised: 5,
};

const configuration = {
  APIURL: url,
  MAILTOURL: furtherInvestorHelpEmail,
  STAGE: stage,
  phoneHours,
  title,
  userType,
  investorType,
};
export default configuration;
